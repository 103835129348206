import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Recuperar o token e a role armazenados nos cookies
  const [authToken, setAuthToken] = useState(Cookies.get("auth_token") || "");
  const [userRole, setUserRole] = useState(Cookies.get("user_role") || "");
  const [username, setUsernameC] = useState(Cookies.get("username") || "");

  useEffect(() => {
    // Armazenar o token e a role nos cookies quando eles forem atualizados
    Cookies.set("auth_token", authToken, {});
    Cookies.set("user_role", userRole, {});
    Cookies.set("username", username, {});
  }, [authToken, userRole, username]);

  return (
    <AuthContext.Provider
      value={{ authToken, setAuthToken, userRole, setUserRole, username, setUsernameC }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
