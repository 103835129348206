import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";

// Data
import Lojas from "layouts/lojas/data/lojas";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import AddLojaForm from "components/AddForm/AddLojaForm";
import EditLojaForm from "components/EditForm/EditLojaForm";

function Tables() {
  const { columns, data, isLoading, isError, error } = Lojas();
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const [selectedContas, setSelectedContas] = useState([]);
  const [selectedGestor, setSelectedGestor] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;

  const handleAddNew = () => {
    setShowAddForm(true);
  };

  const handleEdit = (item) => {
    console.log("Edit Data:", item);
    setEditData(item); // Define os dados da loja a serem editados
    setShowEditForm(true); // Abre o formulário de edição
  };

  // Renderização condicional do formulário de adição de meta
  const renderAddForm = () => {
    if (showAddForm) {
      return <AddLojaForm onClose={() => setShowAddForm(false)} />;
    }
    return null;
  };

  const renderEditForm = () => {
    if (showEditForm && editData) {
      return <EditLojaForm onClose={() => setShowEditForm(false)} data={editData} />;
    }
    return null;
  };

  const uniqueMarketplaces = [...new Set(data.map((item) => item.MarketPlace))];
  const uniqueContas = [...new Set(data.map((item) => item.Conta))];
  const uniqueGestor = [...new Set(data.map((item) => item.Gestor))];

  const filteredData = data.filter(
    (item) =>
      (selectedMarketplaces.length === 0 || selectedMarketplaces.includes(item.MarketPlace)) &&
      (selectedContas.length === 0 || selectedContas.includes(item.Conta)) &&
      (selectedGestor.length === 0 || selectedGestor.includes(item.Gestor))
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  {" "}
                  {/* Adicionado para alinhar horizontalmente */}
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Lojas
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="light"
                      onClick={handleAddNew} // Define a função de clique
                      sx={{ mb: 2, color: "primary" }}
                    >
                      Adicionar Loja
                    </MDButton>
                  </Grid>
                </Grid>
                {renderAddForm()}
              </MDBox>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }} mx={0} px={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueMarketplaces}
                    value={selectedMarketplaces}
                    onChange={(event, newValue) => setSelectedMarketplaces(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="MarketPlace"
                        placeholder="MarketPlace"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueContas}
                    value={selectedContas}
                    onChange={(event, newValue) => setSelectedContas(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Conta" placeholder="Conta" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueGestor}
                    value={selectedGestor}
                    onChange={(event, newValue) => setSelectedGestor(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Gestor"
                        placeholder="Gestor"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {renderEditForm()}
              <Grid alignItems="center" mx={1} px={4}>
                <MDBox pt={0} py={4}>
                  <DataTable
                    table={{
                      columns,
                      rows: filteredData.map((row) => ({
                        ...row,
                        actions: (
                          <Button
                            onClick={() => handleEdit(row)}
                            color="white"
                            variant="contained"
                            size="small"
                          >
                            <EditIcon />
                          </Button>
                        ),
                      })),
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder={true}
                    canSearch={true}
                    canExportCSV={false}
                  />
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
