import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "components/AuthContext/AuthContext";
import { AuthProvider } from "components/AuthContext/AuthContext";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import routes from "routes";
import SignIn from "layouts/authentication/sign-in";
import Cookies from "js-cookie";

import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "App.css";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userRole } = useAuth();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const authToken = Cookies.get("auth_token"); // Use your actual logic to check authentication
    // console.log("User Role:", userRole);
    // console.log("User:", username);
    // console.log(filteredRoutes);
    // Redirect to login if not authenticated
    if (!authToken) {
      navigate("/authentication/sign-in", { replace: true });
    }
  }, [pathname]);

  const getRoutes = (allRoutes) => allRoutes;
  // .filter((route) => route.roles.includes(userRole))
  const AuthenticatedRoutes = useMemo(
    () => (
      <Routes>
        {routes.map((route) => (
          <Route path={route.route} element={route.component} key={route.key} />
        ))}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    ),
    [routes, userRole]
  );

  // Define as rotas não autenticadas
  const UnauthenticatedRoutes = (
    <Routes>
      <Route path="/authentication/sign-in" element={<SignIn />} />
      <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
    </Routes>
  );

  const isAuthenticated = Cookies.get("auth_token");

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline to="/authentication/sign-in" />
      <ToastContainer />
      <AuthProvider>
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Menu"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        {isAuthenticated ? AuthenticatedRoutes : UnauthenticatedRoutes}
      </AuthProvider>
    </ThemeProvider>
  );
}
