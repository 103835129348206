import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { markRegister } from "service/mark";
import { getStores } from "service/stores";

import PropTypes from "prop-types";

function AddMetaForm({ onClose }) {
  const [store, setStore] = useState("");

  const handleChangeValue = (event) => {
    setStore(event.target.value);
  };

  const { data } = useQuery({
    queryKey: ["getStores"],
    queryFn: async () => await getStores(),
  });

  const { mutate } = useMutation({
    mutationFn: (body) => markRegister(body),
    onSuccess: () => {
      toast.success("Meta cadastrada!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao cadastrar meta!");
    },
  });

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      ano: "",
      mes: "",
      conta: "",
      valor: "",
    },
    onSubmit: (values) => {
      mutate({ ...values, conta: store });
    },
  });

  return (
    <Dialog
      open
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>Adicionar Meta</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Ano"
          type="number"
          id="ano"
          value={values.ano}
          onChange={handleChange}
          fullWidth
          required
          inputProps={{ min: 1900, max: 9999 }}
        />
        <TextField
          margin="dense"
          label="Mês"
          type="number"
          id="mes"
          value={values.mes}
          onChange={handleChange}
          fullWidth
          required
          inputProps={{ min: 1, max: 12 }}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="accountLabel">Conta</InputLabel>
          <Select
            labelId="accountLabel"
            id="demo-simple-select"
            value={store}
            label="Conta"
            required
            onChange={handleChangeValue}
            style={{ height: 45 }}
          >
            {data?.map((item) => (
              <MenuItem key={item?.idLoja} value={item?.Conta}>
                {item?.NomeLoja}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          label="Valor"
          type="number"
          name="valor"
          value={values.valor}
          onChange={handleChange}
          fullWidth
          required
          inputProps={{ pattern: "[0-9]*[.,]?[0-9]*" }}
        />
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            //disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            {/*isLoading ? <CircularProgress size={24} /> : "Adicionar Meta"*/}
            Adicionar Meta
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddMetaForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddMetaForm.defaultProps = {
  isLoading: false,
};

export default AddMetaForm;
