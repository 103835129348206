import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { productCoustUpdate } from "service/coust";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function EditCoustForm({ onClose, data, onUpdated }: any) {
  const { mutate, isPending } = useMutation({
    mutationFn: async (param, body) =>
      await productCoustUpdate((param = values.idProduto), (body = values)),
    onSuccess: () => {
      toast.success("Custo atualizado!");
      onClose();
      onUpdated();
    },
    onError: () => {
      toast.error("Erro ao atualizar custo!");
    },
  });

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      idProduto: "",
      custo: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("idProduto", data?.idProduto);
      setFieldValue("custo", data?.custo);
    }
  }, [data]);

  console.log("data", data);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Editar custo</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ID do produto"
          id="idProduto"
          value={values.idProduto}
          onChange={handleChange}
          fullWidth
          required
          disabled
        />
        <TextField
          margin="dense"
          label="Custo"
          id="custo"
          value={values.custo}
          onChange={handleChange}
          fullWidth
          required
        />

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" onClick={() => handleSubmit()}>
            Editar Custo
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

EditCoustForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loja: PropTypes.shape({
    idLoja: PropTypes.number,
    CNPJ: PropTypes.string,
    NomeCNPJ: PropTypes.string,
    razaoSocial: PropTypes.string,
    NomeLoja: PropTypes.string,
    NomeAjustado: PropTypes.string,
    Conta: PropTypes.string,
    Gestor: PropTypes.string,
    MarketPlace: PropTypes.string,
  }).isRequired, // Marque como necessário se o componente não puder funcionar sem esses dados
  isLoading: PropTypes.bool,
  onUpdated: PropTypes.func, // Não obrigatório
};

EditCoustForm.defaultProps = {
  isLoading: false,
  onUpdated: () => {},
};

export default EditCoustForm;
