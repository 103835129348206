import { api } from "./api";

export const markRegister = async (body) => {
  const { data } = await api.post("/metas/", body);
  return data;
};

export const markUpdate = async (param, body) => {
  const { data } = await api.put(`/metas/${param}`, body);
  return data;
};
