import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getProductCousts, productCoustRegister } from "service/coust";

import PropTypes from "prop-types";

function AddCoustForm({ onClose }) {
  const { mutate } = useMutation({
    mutationFn: (body) => productCoustRegister(body),
    onSuccess: () => {
      toast.success("Custo cadastrado!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao cadastrar custo!");
    },
  });

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      idProduto: "",
      custo: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Adicionar custo</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ID Produto"
          type="number"
          id="idProduto"
          value={values.idProduto}
          onChange={handleChange}
          fullWidth
          required
          //inputProps={{ min: 1900, max: 9999 }}
        />
        <TextField
          margin="dense"
          label="Custo"
          type="number"
          id="custo"
          value={values.custo}
          onChange={handleChange}
          fullWidth
          required
          //inputProps={{ min: 1, max: 12 }}
        />
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            //disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            {/*isLoading ? <CircularProgress size={24} /> : "Adicionar Meta"*/}
            Adicionar Custo
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddCoustForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddCoustForm.defaultProps = {
  isLoading: false,
};

export default AddCoustForm;
