import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingModal from "components/Loading/LoadingModal";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import useCsvUpload from "components/FileUpload/CsvUpload";

// Data
import Metas from "layouts/metas/data/metas";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import AddMetaForm from "components/AddForm/AddMetaForm";
import EditMetaForm from "components/EditForm/EditMetaForm";

function Tables() {
  const { columns, collumsDG, data, isLoading, isError, error } = Metas();
  const [selectedAnos, setSelectedAnos] = useState([]);
  const [selectedMeses, setSelectedMeses] = useState([]);
  const [selectedContas, setSelectedContas] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;
  const addMetas = `${baseUrl}/metas/`;
  const { handleUpload } = useCsvUpload(`${baseUrl}/upload-csv-metas/`);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log("Arquivo selecionado:", file.name);
      setSelectedFile(file);
      try {
        await handleUpload(file, setIsUploading);
      } catch (error) {
        console.error("Erro no upload:", error);
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleAddNew = () => {
    setShowAddForm(true);
  };

  const handleEdit = (item) => {
    console.log("Edit Data:", item);
    setEditData(item); // Define os dados da loja a serem editados
    setShowEditForm(true); // Abre o formulário de edição
  };

  // Renderização condicional do formulário de adição de meta
  const renderAddForm = () => {
    if (showAddForm) {
      return (
        <AddMetaForm
          onClose={() => setShowAddForm(false)} // Passa função para fechar o formulário
        />
      );
    }
    return null;
  };

  const renderEditForm = () => {
    if (showEditForm && editData) {
      return <EditMetaForm onClose={() => setShowEditForm(false)} data={editData} />;
    }
    return null;
  };

  const uniqueAno = [...new Set(data.map((item) => item.ano))];
  const uniqueMes = [...new Set(data.map((item) => item.mes))];
  const uniqueConta = [...new Set(data.map((item) => item.conta))];

  const filteredData = data.filter(
    (item) =>
      (selectedAnos.length === 0 || selectedAnos.includes(item.ano)) &&
      (selectedMeses.length === 0 || selectedMeses.includes(item.mes)) &&
      (selectedContas.length === 0 || selectedContas.includes(item.conta))
  );

  const handleDownloadExampleCSV = () => {
    // Lógica para gerar e baixar o CSV de exemplo
    const exampleData = [
      ["ano", "mes", "conta", "valor"],
      [2024, 2, "Netshoes - BOB", 2000.5],
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," + exampleData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exemplo_custos.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  {" "}
                  {/* Adicionado para alinhar horizontalmente */}
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Metas de faturamento
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="light"
                      onClick={handleDownloadExampleCSV} // Define a função de clique
                      sx={{ mb: 2, color: "primary", mr: 2 }}
                    >
                      Baixar Exemplo
                    </MDButton>
                    {/* Botão para carregar o arquivo CSV */}
                    <input
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.binary.macroEnabled.12"
                      onChange={onChange}
                      style={{ display: "none" }}
                      id="csv-input"
                      ref={fileInputRef}
                    />
                    <LoadingModal open={isUploading} />
                    <label htmlFor="csv-input">
                      <MDButton
                        variant="contained"
                        color="light"
                        sx={{ mb: 2, color: "primary", mr: 2 }}
                        onClick={() => {
                          // console.log("Botão Carregar CSV clicado");
                          fileInputRef.current.click();
                        }}
                      >
                        Carregar CSV
                      </MDButton>
                    </label>
                    <MDButton
                      variant="contained"
                      color="light"
                      sx={{ mb: 2, color: "primary", mr: 2 }}
                      onClick={() => {
                        setShowAddForm(true);
                      }}
                    >
                      Adicionar meta
                    </MDButton>
                  </Grid>
                </Grid>
                {renderAddForm()}
              </MDBox>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }} mx={0} px={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueAno}
                    value={selectedAnos}
                    onChange={(event, newValue) => setSelectedAnos(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Ano" placeholder="Ano" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueMes}
                    value={selectedMeses}
                    onChange={(event, newValue) => setSelectedMeses(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Mes" placeholder="Mes" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueConta}
                    value={selectedContas}
                    onChange={(event, newValue) => setSelectedContas(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Conta" placeholder="Conta" />
                    )}
                  />
                </Grid>
              </Grid>
              {renderEditForm()}
              <Grid alignItems="center" mx={1} px={4}>
                <MDBox pt={0} py={4}>
                  <DataTable
                    table={{
                      columns,
                      rows: filteredData.map((row) => ({
                        ...row,
                        actions: (
                          <Button
                            onClick={() => handleEdit(row)}
                            color="white"
                            variant="contained"
                            size="small"
                          >
                            <EditIcon />
                          </Button>
                        ),
                      })),
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder={true}
                    canSearch={true}
                    canExportCSV={true}
                  />
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
