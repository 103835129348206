import { useQuery } from "@tanstack/react-query";
import { useAuth } from "components/AuthContext/AuthContext";
import React, { useMemo } from "react";

export default function Custos() {
  const { authToken } = useAuth();
  const baseUrl = process.env.REACT_APP_API_URL;
  const readCustos = `${baseUrl}/custo-produtos`;

  const columns = useMemo(
    () => [
      { Header: "idProduto", accessor: "idProduto" },
      { Header: "Custo", accessor: "custo" },
      { Header: "ações", accessor: "actions" },
    ],
    []
  );

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["custos"],
    queryFn: async () => {
      const response = await fetch(readCustos, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erro ao buscar metas");
      }
      return response.json();
    },
  });

  const rows = useMemo(() => {
    return (
      data?.map((d) => ({
        idProduto: d.idProduto,
        custo: d.custo,
        // custo: d.custo.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      })) || []
    );
  }, [data]);

  return {
    columns,
    data: rows,
    isLoading,
    isError,
    error,
  };
}
