import { useQuery } from "@tanstack/react-query";
import { useAuth } from "components/AuthContext/AuthContext";
import React, { useMemo } from "react";

export default function Lojas() {
  const { authToken } = useAuth();

  const columns = useMemo(
    () => [
      { Header: "ID Loja", accessor: "idLoja" },
      { Header: "Conta", accessor: "Conta" },
      { Header: "MarketPlace", accessor: "MarketPlace" },
      { Header: "Nome Loja", accessor: "NomeLoja" },
      { Header: "Nome Ajustado", accessor: "NomeAjustado" },
      { Header: "Gestor", accessor: "Gestor" },
      { Header: "Nome CNPJ", accessor: "NomeCNPJ" },
      { Header: "CNPJ", accessor: "CNPJ" },
      { Header: "Razão Social", accessor: "razaoSocial" },
      { Header: "Ações", accessor: "actions" },
    ],
    []
  );

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["lojas"],
    queryFn: async () => {
      const response = await fetch("https://bodybuilders.sevendata.com.br/apiv2/lojas", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erro ao buscar lojas");
      }
      return response.json();
    },
  });

  const rows = useMemo(() => {
    return (
      data?.map((d) => ({
        idLoja: d.idLoja,
        Conta: d.Conta,
        MarketPlace: d.MarketPlace,
        NomeLoja: d.NomeLoja,
        NomeAjustado: d.NomeAjustado,
        Gestor: d.Gestor,
        NomeCNPJ: d.NomeCNPJ,
        CNPJ: d.CNPJ,
        razaoSocial: d.razaoSocial,
      })) || []
    );
  }, [data]);

  return {
    columns,
    data: rows,
    isLoading,
    isError,
    error,
  };
}
