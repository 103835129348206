import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Card } from "@mui/material";
import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../components/AuthContext/AuthContext";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logoImage from "assets/images/logos/bodybuilders.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setAuthToken, setUserRole, setUsernameC } = useAuth();
  const [showPassword, setShowPassword] = useState({
    passwordd: false,
  });

  const loginMutation = useMutation({
    mutationFn: async (loginData) => {
      const body = new URLSearchParams({
        grant_type: "",
        username: loginData.username,
        password: loginData.password,
        scope: "",
        client_id: "",
        client_secret: "",
      });
      const apiURL = `https://bodybuilders.sevendata.com.br/apiv2/token`;
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
        body: body.toString(),
      });

      if (!response.ok) {
        throw new Error("Nome de usuário ou senha inválidos");
      }

      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);

      return data;
    },
    onSuccess: (data) => {
      Cookies.set("auth_token", data.access_token, {});
      setAuthToken(data.access_token);
      Cookies.set("user_role", data.role, {});
      setUserRole(data.role);
      Cookies.set("username", username, {});
      setUsernameC(username);
      console.log(username);
      navigate("/home");
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const handleLogin = () => {
    setErrorMessage("");
    loginMutation.mutate({ username, password });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <img src={logoImage} alt="Logotipo" style={{ maxWidth: "100%", height: "auto" }} />
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <MDBox mb={2}>
              <MDInput
                type="username"
                label="Username"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword.passwordd ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => togglePasswordVisibility("passwordd")} edge="end">
                        {showPassword.passwordd ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              ></MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="dark" fullWidth onClick={handleLogin}>
                Entrar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
