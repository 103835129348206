import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { storeUpdate } from "service/stores";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function EditLojaForm({ onClose, data }: any) {
  const { mutate, isPending } = useMutation({
    mutationFn: async (param, body) => await storeUpdate((param = values.idLoja), (body = values)),
    onSuccess: () => {
      toast.success("Loja atualizada!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao atualizar loja!");
    },
  });

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      idLoja: "",
      CNPJ: "",
      NomeCNPJ: "",
      razaoSocial: "",
      NomeLoja: "",
      NomeAjustado: "",
      Conta: "",
      Gestor: "",
      MarketPlace: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("idLoja", data?.idLoja);
      setFieldValue("CNPJ", data?.CNPJ);
      setFieldValue("NomeCNPJ", data?.NomeCNPJ);
      setFieldValue("razaoSocial", data?.razaoSocial);
      setFieldValue("NomeLoja", data?.NomeLoja);
      setFieldValue("NomeAjustado", data?.NomeAjustado);
      setFieldValue("Conta", data?.Conta);
      setFieldValue("Gestor", data?.Gestor);
      setFieldValue("MarketPlace", data?.MarketPlace);
    }
  }, [data]);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Editar Loja</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ID da Loja"
          id="idLoja"
          value={values.idLoja}
          onChange={handleChange}
          fullWidth
          required
          disabled
        />
        <TextField
          margin="dense"
          label="CNPJ"
          id="CNPJ"
          value={values.CNPJ}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome CNPJ"
          id="NomeCNPJ"
          value={values.NomeCNPJ}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Razão Social"
          id="razaoSocial"
          value={values.razaoSocial}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome da Loja"
          id="NomeLoja"
          value={values.NomeLoja}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome Ajustado"
          id="NomeAjustado"
          value={values.NomeAjustado}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Conta"
          id="Conta"
          value={values.Conta}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Gestor"
          id="Gestor"
          value={values.Gestor}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="MarketPlace"
          id="MarketPlace"
          value={values.MarketPlace}
          onChange={handleChange}
          fullWidth
          required
        />
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" onClick={() => handleSubmit()}>
            Editar Loja
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

EditLojaForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loja: PropTypes.shape({
    idLoja: PropTypes.number,
    CNPJ: PropTypes.string,
    NomeCNPJ: PropTypes.string,
    razaoSocial: PropTypes.string,
    NomeLoja: PropTypes.string,
    NomeAjustado: PropTypes.string,
    Conta: PropTypes.string,
    Gestor: PropTypes.string,
    MarketPlace: PropTypes.string,
  }).isRequired, // Marque como necessário se o componente não puder funcionar sem esses dados
  isLoading: PropTypes.bool,
};

EditLojaForm.defaultProps = {
  isLoading: false,
};

export default EditLojaForm;
