import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { useAuth } from "components/AuthContext/AuthContext";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { models } from "powerbi-client";
import { useTokenBI } from "states/token_msal";
import { CircularProgress, Typography, Box } from "@mui/material";

function Dashboard() {
  const { userRole, authToken } = useAuth();
  const [report, setReport] = useState(null);
  const embedContainerRef = useRef(null);
  const { token, refreshToken } = useTokenBI();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function embedPowerBIReport() {
      const embedContainer = embedContainerRef.current;

      // Configurações do Power BI
      const embedConfig = {
        type: "report",
        tokenType: models.TokenType.Embed, // Usando token de embed
        accessToken: token, // Substitua pelo seu token
        embedUrl:
          "https://app.powerbi.com/reportEmbed?reportId=90d900ed-2f49-4606-be29-4a2bd264aa45&groupId=31c7f9aa-23c2-43c9-8061-0ae5bf776081&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d", // Substitua pelo URL do relatório
        id: "90d900ed-2f49-4606-be29-4a2bd264aa45", // Substitua pelo ID do relatório
        permissions: models.Permissions.All,
        pageName: "ReportSection1f65eaa79be1ce7abb80",
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
          bars: {
            statusBar: {
              visible: false,
            },
          },
          enableRls: false,
          style: { border: "none" },
        },
      };

      // Embedding the report
      const reportInstance = powerbi.embed(embedContainer, embedConfig);
      const iframe = embedContainer.querySelector("iframe");
      if (iframe) {
        iframe.classList.add("powerbi-frame");
      }
      setReport(reportInstance);

      reportInstance.on("error", function (event) {
        console.error(event.detail);
      });

      // Espera o relatório ser carregado e renderizado
      await new Promise((resolve) => {
        reportInstance.on("loaded", async function () {
          setLoading(false);
          await applyDateFilter(reportInstance); // Aplicar o filtro após o carregamento
          resolve();
        });
      });
    }

    if (token) {
      embedPowerBIReport();
    }
  }, [token]);

  useEffect(() => {
    refreshToken({
      group_id: "31c7f9aa-23c2-43c9-8061-0ae5bf776081",
      report_id: "90d900ed-2f49-4606-be29-4a2bd264aa45",
      authToken,
    });
  }, []);

  const applyDateFilter = async (reportInstance) => {
    try {
      const pages = await reportInstance.getPages();
      let page = pages.find((page) => page.isActive);

      if (page) {
        const visuals = await page.getVisuals();
        let slicer = visuals.find(
          (visual) => visual.type === "slicer" && visual.name === "11f4791c4a862c36d6c5"
        );

        if (slicer) {
          const now = new Date();
          const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
          const today =
            new Date(
              Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours() - 3
              )
            )
              .toISOString()
              .split("T")[0] + "T-03:00:00.000Z";

          const filter = {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
              table: "nutristore ads_shopee",
              column: ".date",
            },
            filterType: models.FilterType.Advanced,
            logicalOperator: "And",
            conditions: [
              {
                operator: "GreaterThanOrEqual",
                value: startOfMonth,
              },
              {
                operator: "LessThan",
                value: today,
              },
            ],
          };

          await slicer.setSlicerState({ filters: [filter] });
          console.log("Date slicer was set to the current month.");
        } else {
          console.error("Slicer not found.");
        }
      } else {
        console.error("Active page not found.");
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              height: "800px",
              padding: 0,
              margin: 0,
              border: "none",
              boxShadow: "none",
              outline: "none",
              overflow: "hidden",
            }}
          >
            <MDBox
              mb={1}
              display="auto"
              alignItems="center"
              style={{
                height: "100%",
              }}
            >
              {loading && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <CircularProgress />
                  <Typography variant="h6" marginTop={2}>
                    Carregando dados...
                  </Typography>
                </Box>
              )}
              <div
                ref={embedContainerRef}
                id="embedContainer"
                style={{
                  width: "95%",
                  height: "95%",
                  visibility: loading ? "hidden" : "visible",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
