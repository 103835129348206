import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { storeUpdate } from "service/stores";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
} from "@mui/material";

function NumberUserRegister({ onClose, data }: any) {
  const { mutate, isPending } = useMutation({
    mutationFn: async (param, body) => await storeUpdate((param = values.idLoja), (body = values)),
    onSuccess: () => {
      toast.success("Loja atualizada!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao atualizar loja!");
    },
  });

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      role: "",
      username: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("role", data?.role);
      setFieldValue("username", data?.username);
    }
  }, [data]);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle textAlign="center">Conectar número</DialogTitle>
      <DialogContent>
        <Stack
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          gap={4}
          padding={2}
        >
          <Typography variant="h5">Nome: {values.username}</Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: 700, color: "#000000" }}>Função: </span>
            {values.role}
          </Typography>
        </Stack>

        <Stack width="100%">
          <img src="https://picsum.photos/400/400" alt="qr-code" />
        </Stack>
        <DialogActions>
          <Stack width="100%" justifyContent="center">
            <Button onClick={onClose} color="primary">
              Cancelar
            </Button>
          </Stack>
          {/* <Button type="submit" color="primary" onClick={() => handleSubmit()}>
            Editar Loja
          </Button> */}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

NumberUserRegister.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loja: PropTypes.shape({
    idLoja: PropTypes.number,
    CNPJ: PropTypes.string,
    NomeCNPJ: PropTypes.string,
    razaoSocial: PropTypes.string,
    NomeLoja: PropTypes.string,
    NomeAjustado: PropTypes.string,
    Conta: PropTypes.string,
    Gestor: PropTypes.string,
    MarketPlace: PropTypes.string,
  }).isRequired, // Marque como necessário se o componente não puder funcionar sem esses dados
  isLoading: PropTypes.bool,
};

NumberUserRegister.defaultProps = {
  isLoading: false,
};

export default NumberUserRegister;
