import { useQuery } from "@tanstack/react-query";
import { useAuth } from "components/AuthContext/AuthContext";
import React, { useMemo } from "react";

export default function Metas() {
  const { authToken } = useAuth();
  const baseUrl = process.env.REACT_APP_API_URL;
  const readMetas = `${baseUrl}/metas`;

  const columns = useMemo(
    () => [
      // { Header: "idMeta", accessor: "idMeta" },
      { Header: "ano", accessor: "ano" },
      { Header: "mes", accessor: "mes" },
      { Header: "conta", accessor: "conta" },
      { Header: "valor", accessor: "valor" },
      { Header: "ações", accessor: "actions" },
    ],
    []
  );

  const columnsDG = [
    { headerName: "idMeta", field: "idMeta" },
    { headerName: "ano", field: "ano" },
    { headerName: "mes", field: "mes" },
    { headerName: "conta", field: "conta" },
    { headerName: "valor", field: "valor" },
    // { headerName: "Ações", field: "actions" },
  ];

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["metas"],
    queryFn: async () => {
      const response = await fetch(readMetas, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Erro ao buscar metas");
      }
      return response.json();
    },
  });

  const rows = useMemo(() => {
    return (
      data?.map((d) => ({
        idMeta: d.idMeta,
        ano: d.ano,
        mes: d.mes,
        conta: d.conta,
        valor: d.valor,
        // valor: d.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      })) || []
    );
  }, [data]);

  return {
    columns,
    columnsDG,
    data: rows,
    isLoading,
    isError,
    error,
  };
}
