import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useAuth } from "components/AuthContext/AuthContext";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Dashboard() {
  const { userRole } = useAuth(); // Usar o contexto de autenticação

  const adminReportLink =
    "https://app.powerbi.com/reportEmbed?reportId=f9ffc665-9834-49d9-aaba-02b2c51caae1&autoAuth=true&ctid=ee58755a-5262-4386-9657-5ff6c4c883f8&navContentPaneEnabled=false&filterPaneEnabled=false";
  const userReportLink =
    "https://app.powerbi.com/reportEmbed?reportId=5a269993-5e4b-467e-b215-f00860c7ffd1&autoAuth=true&ctid=ee58755a-5262-4386-9657-5ff6c4c883f8&navContentPaneEnabled=false&filterPaneEnabled=false";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox mb={1} display="flex" alignItems="center">
              {/* <MDTypography variant="h5" color="secundary">
                Dashboard
              </MDTypography> */}
              {userRole === "admin" ? (
                <>
                  <MDButton
                    size="small"
                    variant="contained"
                    color="dark"
                    onClick={() => window.open(adminReportLink, "_blank")}
                  >
                    Relatório Custo
                  </MDButton>
                  <MDButton
                    size="small"
                    variant="contained"
                    color="dark"
                    onClick={() => window.open(userReportLink, "_blank")}
                    sx={{ mb: 0, mx: 2 }}
                  >
                    Relatório Antigo
                  </MDButton>
                </>
              ) : (
                <MDButton
                  size="small"
                  variant="contained"
                  color="dark"
                  onClick={() => window.open(userReportLink, "_blank")}
                >
                  Acessar Relatório Completo
                </MDButton>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <iframe
              title="Relatorio"
              width="100%"
              height="700"
              src={userRole === "admin" ? adminReportLink : userReportLink}
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
