import { api } from "./api";

export const getStores = async (body) => {
  const { data } = await api.get("/lojas", body);
  return data;
};

export const storeRegister = async (body) => {
  const { data } = await api.post("/lojas/", body);
  return data;
};

export const storeUpdate = async (param, body) => {
  const { data } = await api.put(`/lojas/${param}`, body);
  return data;
};
