import Dashboard from "layouts/dashboard";
import Inicio from "layouts/inicio";
import Metas from "layouts/metas";
import Lojas from "layouts/lojas";
import Custos from "layouts/custoProdutos";
import Profile from "layouts/profile";
import Faturamento from "layouts/faturamento";
import NomesPaginas from "layouts/nomes_paginas";
import CurvaABC from "layouts/curvaabc";
import Relatorio from "layouts/relatorio";
import Ads from "layouts/ads";
import Usuarios from "layouts/usuarios";

// @mui icons
import Icon from "@mui/material/Icon";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AbcIcon from "@mui/icons-material/Abc";
import StarRateIcon from "@mui/icons-material/StarRate";

const routes = [
  {
    type: "collapse",
    name: "Inicio",
    key: "inicio",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/inicio",
    component: <Inicio />,
    roles: ["admin", "user"], // Todas as roles podem acessar
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    roles: ["admin", "user"], // Todas as roles podem acessar
  },
  {
    type: "collapse",
    name: "Faturamento",
    key: "faturamento",
    icon: <PriceChangeIcon />,
    route: "/faturamento",
    component: <Faturamento />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "Relatório",
    key: "relatorio",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/relatorio",
    component: <Relatorio />,
    roles: ["admin"],
  },
  {
    type: "collapse",
    name: "Curva ABC",
    key: "Curba ABC",
    icon: <AbcIcon />,
    route: "/curva-abc",
    component: <CurvaABC />,
    roles: ["admin", "user"],
  },
  {
    type: "collapse",
    name: "ADS",
    key: "ads",
    icon: <StarRateIcon />,
    route: "/ads",
    component: <Ads />,
    roles: ["admin", "user"],
  },
  // {
  //   type: "collapse",
  //   name: "NomesPaginas",
  //   key: "nomesPaginas",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/nomespaginas",
  //   component: <NomesPaginas />,
  //   roles: ["admin"],
  // },
  {
    type: "collapse",
    name: "Lojas",
    key: "lojas",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/lojas",
    component: <Lojas />,
    roles: ["admin", "user"],
  },
  // {
  //   type: "collapse",
  //   name: "Usuários",
  //   key: "usuarios",
  //   icon: <Icon fontSize="small">people</Icon>,
  //   route: "/usuarios",
  //   component: <Usuarios />,
  //   roles: ["admin"],
  // },
  {
    type: "collapse",
    name: "Metas",
    key: "metas",
    icon: <Icon fontSize="small">flag</Icon>,
    route: "/metas",
    component: <Metas />,
    roles: ["admin", "user"],
  },
  {
    type: "collapse",
    name: "Custos",
    key: "custos",
    icon: (
      <Icon fontSize="small">
        <PriceChangeIcon></PriceChangeIcon>
      </Icon>
    ),
    route: "/custos",
    component: <Custos />,
    roles: ["admin"], // Apenas admin pode acessar
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    roles: ["admin", "user"],
  },
];

export default routes;
