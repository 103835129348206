import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { useAuth } from "components/AuthContext/AuthContext";
import moment from "moment";

// export const useTokenBI = create(
//   persist(
//     (set, get) => ({
//       token: null,
//       expires_in: null,
//       refreshToken: async ({ group_id, report_id, authToken }) => {
//         // console.log("Retorno da função:" + get().expires_in);
//         // console.log("Tratada:" + new Date(get().expires_in));
//         // console.log("New Date:" + new Date());
//         // console.log(moment().isAfter(get().expires_in));
//         if (get().expires_in == null || moment().isAfter(get().expires_in)) {
//           const response = await fetch(
//             `https://bodybuilders.sevendata.com.br/apiv2/powerbi/generate-token/${group_id}/${report_id}`,
//             // `http://192.168.0.51:8000/powerbi/generate-token/${group_id}/${report_id}`,
//             {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${authToken}`,
//               },
//             }
//           );
//           const data = await response.json();
//           //   console.log("teste" + data.expiration);
//           set({ token: data.token, expires_in: data.expiration });
//         }
//       },
//     }),
//     {
//       name: "token-ml-storage", // name of the item in the storage (must be unique)
//       storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
//     }
//   )
// );

export const useTokenBI = create(
  persist(
    (set, get) => ({
      token: null,
      expires_in: null,
      refreshToken: async ({ group_id, report_id, authToken }) => {
        // console.log("Retorno da função:" + get().expires_in);
        // console.log("Tratada:" + new Date(get().expires_in));
        // console.log("New Date:" + new Date());
        // console.log(moment().isAfter(get().expires_in));
        const response = await fetch(
          `https://bodybuilders.sevendata.com.br/apiv2/powerbi/generate-token/${group_id}/${report_id}`,
          // `http://192.168.0.51:8000/powerbi/generate-token/${group_id}/${report_id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const data = await response.json();
        //   console.log("teste" + data.expiration);
        set({ token: data.token, expires_in: data.expiration });
      },
    }),
    {
      name: "token-ml-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
