import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { storeRegister } from "service/stores";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function AddLojaForm({ onClose }) {
  const { mutate } = useMutation({
    mutationFn: (body) => storeRegister(body),
    onSuccess: () => {
      toast.success("Loja cadastrada!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao cadastrar loja!");
    },
  });

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      idLoja: "",
      CNPJ: "",
      NomeCNPJ: "",
      razaoSocial: "",
      NomeLoja: "",
      NomeAjustado: "",
      Conta: "",
      Gestor: "",
      MarketPlace: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Adicionar Loja</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ID da Loja"
          id="idLoja"
          value={values.idLoja}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="CNPJ"
          id="CNPJ"
          value={values.CNPJ}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome CNPJ"
          id="NomeCNPJ"
          value={values.NomeCNPJ}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Razão Social"
          id="razaoSocial"
          value={values.razaoSocial}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome da Loja"
          id="NomeLoja"
          value={values.NomeLoja}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Nome Ajustado"
          id="NomeAjustado"
          value={values.NomeAjustado}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Conta"
          id="Conta"
          value={values.Conta}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Gestor"
          id="Gestor"
          value={values.Gestor}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="MarketPlace"
          id="MarketPlace"
          value={values.MarketPlace}
          onChange={handleChange}
          fullWidth
          required
        />
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleSubmit()}>
            Adicionar Loja
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddLojaForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddLojaForm.defaultProps = {
  isLoading: false,
};

export default AddLojaForm;
