import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingModal from "components/Loading/LoadingModal";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import useCsvUpload from "components/FileUpload/CsvUpload";

// Data
import Custos from "layouts/custoProdutos/data/custos";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import AddCoustForm from "components/AddForm/AddCoustForm";
import EditCoustForm from "components/EditForm/EditCoustForm";

function Tables() {
  const { columns, data, isLoading, isError, error } = Custos();
  const [selectedProduto, setSelectedProduto] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;
  const { handleUpload } = useCsvUpload(`${baseUrl}/upload-csv-custos/`);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log("Arquivo selecionado:", file.name);
      setSelectedFile(file);
      try {
        await handleUpload(file, setIsUploading);
      } catch (error) {
        console.error("Erro no upload:", error);
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleAddNew = () => {
    setShowAddForm(true);
  };

  const handleRefreshData = () => {
    window.location.reload();
  };

  const handleEdit = (item) => {
    console.log("Edit Data:", item);
    setEditData(item); // Define os dados da loja a serem editados
    setShowEditForm(true); // Abre o formulário de edição
  };

  // Renderização condicional do formulário de adição de meta
  const renderAddForm = () => {
    if (showAddForm) {
      return <AddCoustForm onClose={() => setShowAddForm(false)} />;
    }
    return null;
  };

  const renderEditForm = () => {
    if (showEditForm && editData) {
      return (
        <EditCoustForm
          data={editData}
          onClose={() => setShowEditForm(false)}
          onUpdated={handleRefreshData}
        />
      );
    }
    return null;
  };

  const uniqueProduto = [...new Set(data.map((item) => item.idProduto))];

  const filteredData = data.filter(
    (item) => selectedProduto.length === 0 || selectedProduto.includes(item.idProduto)
  );

  const handleDownloadExampleCSV = () => {
    // Lógica para gerar e baixar o CSV de exemplo
    const exampleData = [
      ["idProduto", "custo"],
      [5001, 17.2],
      [9582, 12.6],
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," + exampleData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exemplo_custos.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container alignItems="center" justifyContent="space-between">
                  {" "}
                  {/* Adicionado para alinhar horizontalmente */}
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Custo de Produto
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="light"
                      onClick={handleDownloadExampleCSV} // Define a função de clique
                      sx={{ mb: 2, color: "primary", mr: 2 }}
                    >
                      Baixar Exemplo
                    </MDButton>
                    {/* Botão para carregar o arquivo CSV */}
                    <input
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.binary.macroEnabled.12"
                      onChange={onChange}
                      style={{ display: "none" }}
                      id="csv-input"
                      ref={fileInputRef}
                    />
                    <LoadingModal open={isUploading} />
                    <label htmlFor="csv-input">
                      <MDButton
                        variant="contained"
                        color="light"
                        sx={{ mb: 2, color: "primary", mr: 2 }}
                        onClick={() => {
                          // console.log("Botão Carregar CSV clicado");
                          fileInputRef.current.click();
                        }}
                      >
                        Carregar CSV
                      </MDButton>
                    </label>
                    <MDButton
                      variant="contained"
                      color="light"
                      sx={{ mb: 2, color: "primary", mr: 2 }}
                      onClick={() => {
                        setShowAddForm(true);
                      }}
                    >
                      Adicionar custo
                    </MDButton>
                  </Grid>
                </Grid>
                {renderAddForm()}
              </MDBox>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }} mx={0} px={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    size="small"
                    multiple
                    options={uniqueProduto}
                    value={selectedProduto}
                    onChange={(event, newValue) => setSelectedProduto(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Produto"
                        placeholder="ID Produto"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {renderEditForm()}
              <Grid alignItems="center" mx={1} px={4}>
                <MDBox pt={0} py={4}>
                  <DataTable
                    table={{
                      columns,
                      rows: filteredData.map((row) => ({
                        ...row,
                        actions: (
                          <Button
                            onClick={() => handleEdit(row)}
                            color="white"
                            variant="contained"
                            size="small"
                          >
                            <EditIcon />
                          </Button>
                        ),
                      })),
                    }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder={true}
                    canSearch={true}
                    canExportCSV={true}
                  />
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
