import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "components/AuthContext/AuthContext";

const container = document.getElementById("app");
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </QueryClientProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
