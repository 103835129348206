import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { markUpdate } from "service/mark";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

function EditMetaForm({ onClose, data }: any) {
  const { mutate, isPending } = useMutation({
    mutationFn: async (param, body) => await markUpdate((param = values.idMeta), (body = values)),
    onSuccess: () => {
      toast.success("Loja atualizada!");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao atualizar loja!");
    },
  });

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      idMeta: "",
      ano: "",
      conta: "",
      mes: "",
      valor: "",
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (data) {
      setFieldValue("idMeta", data?.idMeta);
      setFieldValue("ano", data?.ano);
      setFieldValue("conta", data?.conta);
      setFieldValue("mes", data?.mes);
      setFieldValue("valor", data?.valor);
    }
  }, [data]);

  console.log("data", data);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Editar meta</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="ID da Meta"
          id="idMeta"
          value={values.idMeta}
          onChange={handleChange}
          fullWidth
          required
          disabled
        />
        <TextField
          margin="dense"
          label="Ano"
          id="ano"
          value={values.ano}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Mês"
          id="mes"
          value={values.mes}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Conta"
          id="conta"
          value={values.conta}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Valor"
          id="valor"
          value={values.valor}
          onChange={handleChange}
          fullWidth
          required
        />

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" onClick={() => handleSubmit()}>
            Editar Meta
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

EditMetaForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loja: PropTypes.shape({
    idLoja: PropTypes.number,
    CNPJ: PropTypes.string,
    NomeCNPJ: PropTypes.string,
    razaoSocial: PropTypes.string,
    NomeLoja: PropTypes.string,
    NomeAjustado: PropTypes.string,
    Conta: PropTypes.string,
    Gestor: PropTypes.string,
    MarketPlace: PropTypes.string,
  }).isRequired, // Marque como necessário se o componente não puder funcionar sem esses dados
  isLoading: PropTypes.bool,
};

EditMetaForm.defaultProps = {
  isLoading: false,
};

export default EditMetaForm;
