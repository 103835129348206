import React from "react";
import PropTypes from "prop-types"; // Importe PropTypes
import { Dialog, CircularProgress, DialogContent, Typography } from "@mui/material";

function LoadingModal({ open }) {
  return (
    <Dialog open={open}>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 20 }}
      >
        <CircularProgress />
        <Typography variant="overline" style={{ marginTop: 10 }}>
          Processando...
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

// Definindo PropTypes
LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired, // Define que 'open' é obrigatório e deve ser um booleano
};

export default LoadingModal;
