import { useAuth } from "components/AuthContext/AuthContext";

function useCsvUpload(apiUrl) {
  const { authToken } = useAuth();
  // console.log("API URL:", apiUrl);

  const handleUpload = async (file, setUploadStatus) => {
    setUploadStatus(true); // Começa o upload
    const formData = new FormData();
    formData.append("file", file);

    // console.log("Form Data:", formData);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Erro ao fazer o upload do arquivo");
      }
      alert("Upload realizado com sucesso!");
      return response.json();
    } catch (error) {
      alert("Erro no upload: " + error.message);
      throw error; // Propaga o erro para ser tratado pelo componente
    } finally {
      setUploadStatus(false); // Finaliza o upload independentemente do resultado
    }
  };

  return { handleUpload };
}

export default useCsvUpload;
