import { api } from "./api";

export const getProductCousts = async (body) => {
  const { data } = await api.get("/custo-produtos/", body);
  return data;
};

export const productCoustRegister = async (body) => {
  const { data } = await api.post("/custo-produto/", body);
  return data;
};

export const productCoustUpdate = async (param, body) => {
  const { data } = await api.put(`/custo-produto/${param}`, body);
  return data;
};
