import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
});

api.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem("access_token");

    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export { api };
